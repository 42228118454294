import React, { Suspense, useState } from "react";
import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";

const Loading = React.lazy(() => import("./pages/Loading/Loading"));
const Verify = React.lazy(() => import("./pages/Verify/Verify"));
const Error = React.lazy(() => import("./pages/Error/Error"));
const SharedLayout = React.lazy(() =>
  import("./pages/SharedLayout/SharedLayout")
);
const Policy = React.lazy(() => import("./pages/Policy/Policy"));
// CENTRAL PARK RESIDENCES
const TourCentralPark = React.lazy(() =>
  import("./pages/Tour/TourCentralPark")
);
const LocationCentralPark = React.lazy(() =>
  import("./pages/Location/LocationCentralPark")
);
const PlanCentralPark = React.lazy(() => import("./pages/Plan/Plan"));
const HouseCentralPark = React.lazy(() => import("./pages/House/House"));
const UtilsCentralPark = React.lazy(() =>
  import("./pages/Utilities/Utilities")
);
const EBrochureCentralPark = React.lazy(() =>
  import("./pages/EBrochure/EBrochureCentralPark")
);

function App() {
  const languageState = useSelector((state) => state.language);
  const { locale, messages } = languageState;
  const [checkVerify, setCheckVerify] = useState(true);

  return (
    <IntlProvider messages={messages} locale={locale}>
      <div className="App">
        <Suspense fallback={<div>Loading...</div>}>
          <Router>
            {checkVerify === true ? (
              <Routes>
                <Route path="" index element={<Loading />} />
                <Route element={<SharedLayout />}>

                  <Route
                    index
                    path="/tour"
                    element={<TourCentralPark />}
                  />
                  <Route
                    index
                    path="/plan"
                    element={<PlanCentralPark />}
                  />
                  <Route
                    index
                    path="/house"
                    element={<HouseCentralPark />}
                  />
                  <Route
                    index
                    path="/utils"
                    element={<UtilsCentralPark />}
                  />
                  <Route
                    index
                    path="/location"
                    element={<LocationCentralPark />}
                  />
                  <Route
                    index
                    path="/ebrochure"
                    element={<EBrochureCentralPark />}
                  />

                  
                  <Route
                    index
                    path="/chinh-sach-bao-mat"
                    element={<Policy />}
                  />
                </Route>
                <Route path="*" element={<Error />} />
              </Routes>
            ) : (
              <Routes>
                <Route
                  path="*"
                  element={<Verify setCheckVerify={setCheckVerify} />}
                />
              </Routes>
            )}
          </Router>
        </Suspense>
      </div>
    </IntlProvider>
  );
}

export default App;
